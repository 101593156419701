export const getOverviewData = () => {
    return [
        {
            sectionImage: 'Overview/champions-club.png',
            //sectionTitle: 'West VVIP Club',
            paragraph: 'The sporty elegance of this club combined with the incredible sideline views, turnkey technology, and stunning finishes creates an unmatchable atmosphere.',
            area: '9,700',
            reception: '300',
            banquet: '150'
        }
    ]
}

export const getCarouselImages = () => {
    return [

        'RoKitClub/Photos/rokit-club-02.jpg',
        'RoKitClub/Photos/rokit-club-03.jpg',
		'RoKitClub/Photos/rokit-club-06.jpg',
        'RoKitClub/Photos/rokit-club-07.jpg',
        'RoKitClub/Photos/rokit-club-08.jpg',
        'RoKitClub/Photos/rokit-club-04.jpg',
        'RoKitClub/Photos/rokit-club-05.jpg',
		'RoKitClub/Photos/rokit-club-bg.jpg',
        'RoKitClub/Photos/rokit-club-09.jpg',
        'RoKitClub/Photos/rokit-club-10.jpg',
        'RoKitClub/Photos/rokit-club-11.jpg',
        'RoKitClub/Photos/rokit-club-12.jpg',
        'RoKitClub/Photos/rokit-club-13.jpg',
        'RoKitClub/Photos/rokit-club-14.jpg',
        'RoKitClub/Photos/rokit-club-15.jpg',
        'RoKitClub/Photos/rokit-club-16.jpg',
        'RoKitClub/Photos/rokit-club-17.jpg'
    ]
}

export const getEventPhotos = () => {
    return [
        'RoKitClub/event-photos/champions-club-event-1.jpg',
        'RoKitClub/event-photos/champions-club-event-2.jpg',
    ]
}
export const getFieldViewImages = () => {
    return [
        'RoKitClub/FieldView/VR_Section_C134_8_11_Black.jpg',
        'RoKitClub/FieldView/VR_Section_C134_8_11_Down.jpg',
        'RoKitClub/FieldView/VR_Section_C134_8_11_Front.jpg',
        'RoKitClub/FieldView/VR_Section_C134_8_11_Left.jpg',
        'RoKitClub/FieldView/VR_Section_C134_8_11_Right.jpg',
        'RoKitClub/FieldView/VR_Section_C134_8_11_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'RoKitClub/Layout/layout-rokit-club.jpg',
		'RoKitClub/Layout/layout-champions-club2.jpg',
		'RoKitClub/Layout/layout-champions-club3.jpg',
		'RoKitClub/Layout/layout-champions-club4.jpg',
		'RoKitClub/Layout/layout-champions-club5.jpg',
    ]
}
